.InlineButton {
  display: inline-block;
  position: relative;
  color: #2E5266;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 300;
  text-decoration: none;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.InlineButton:hover {
  color: #6E8898;
}

.InlineButton:before,
.InlineButton:after {
  content: '';
  position: absolute;
  left: -2px;
  bottom: -2px;
  padding: 0 2px;
  background: #E2C044;
  opacity: 0.2;
  width: 100%;
  height: 14px;
  transition: opacity 0.1s ease-out;
}

.InlineButton:before {
  background: #6E8898;
  opacity: 0;
  transform: translateX(15%);
  transition: transform 0.2s ease-out, opacity 0.1s ease-out;
}

.InlineButton:hover:before {
  opacity: 0.3;
  transform: translateX(0);
}
.InlineButton:hover:after {
  opacity: 0;
}