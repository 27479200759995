.ToggleButton {
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease;
}

.ToggleButton-active,
.ToggleButton:hover {
  transform: translateX(50px);
}

.ToggleButton .more-icon {
  position: absolute;
  transform: translateY(20px);
  left: -50px;
  width: 40px;
  height: 40px;
  opacity: 0;
  visibility: hidden;
}

.ToggleButton-active .more-icon,
.ToggleButton:hover .more-icon {
  transform: translateY(0);
  opacity: 0.6;
  visibility: visible;
  transition: opacity 0.6s ease;
}