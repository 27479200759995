@import-normalize; /* bring in normalize.css styles */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  color: #2E5266;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F1F1F1;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Didact Gothic', sans-serif;
}

h1 {
  font-size: 58px;
  line-height: 80px;
}

p,
ul {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  outline: none;
}

.strong {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: bold;
}