.DynamicSection {
  height: 100vh;
  padding-bottom: 12vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.DynamicSection h1 {
  color: #2E5266;
  margin-bottom: 0;
}

.DynamicSection-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.DynamicSection-header p,
.DynamicSection-header ul {
  font-size: 18px;
}

.DynamicSection-header li {
  margin-bottom: 10px;
}

.DynamicSection-content {
  padding: 0 40px;
}

@media screen and (min-width: 1024px) {
  .DynamicSection-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0;
  }
}

.DynamicSection-active-content {
  padding-bottom: 40px;
}

.DynamicSection-intro {
  padding-top: 20px;
  width: 100%;
  transition: transform 0.2s linear;
  background: #F1F1F1;
}

.DynamicSection-intro:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: -1px -72px 64px -52px rgba(0,0,0,0.2);
  opacity: 0;
  z-index: -1;
}

.DynamicSection-intro-collapsed {
  position: fixed;
  top: 12vh;
  left: 0;
  transform: translateY(76vh);
  filter: blur(2px);
  pointer-events: none;
}

.DynamicSection-intro-collapsed:after {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1s 0.2s;
}

.icon {
  border: 0;
  background: transparent;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.6s;
}

.icon:hover {
  opacity: 1;
  transform: scale(1.2);
}

.icon svg {
  width: 60px;
  height: 60px;
  fill: #2E5266;
}