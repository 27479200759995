.PortfolioItem {
  margin-bottom: 20px;
}

.PortfolioItem-header {
  padding: 10px 0;
}

.PortfolioItem-heading {
  margin: 0;
  font-size: 22px;
}

.PortfolioItem-techlist {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.PortfolioItem-tech-header {
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  border-bottom: 4px solid #9FB1BC;
}

.PortfolioItem-techlist-item {
  font-size: 12px;
  font-weight: 700;
  padding: 2px 10px;
  margin-right: 10px;
  background: #6E8898;
  border-radius: 6px;
  color: #f1f1f1;
}

.PortfolioItem-bg {
  background-size: cover;
  background-position: center center;
  height: 300px;
}

@media screen and (min-width: 1200px) {
  .PortfolioItem-expanded {
    position: relative;
    display: flex;
    align-items: center;
    height: 500px;
  }
  
  .PortfolioItem-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 10vw);
    height: 100%;
    animation: easeLeft 0.4s forwards;
  }
  
  .PortfolioItem-description {
    padding: 20px 0 20px 40px;
    background: #f1f1f1;
    max-height: 420px;
    animation: easeRight 0.4s forwards;
    box-shadow: -35px -5px 95px -35px rgba(0,0,0,0.75);
    opacity: 0.94;
  }
}

@keyframes easeLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10vw);
  }
}

@keyframes easeRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10vw);
  }
}